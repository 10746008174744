import Blog from "../components/blog";
import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import cover from "./assets/cover.svg";

const DetailedBlog = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundPosition: "",
          backgroundSize: "cover",
        }}
        className=" w-full h-[400px] flex items-center flex-col justify-center px-4 md:px-14"
      >
        <p className=" text-center font-Outfit font-medium text-sm text-white">
          May 1, 2024
        </p>
        <p className=" text-white font-bold font-Outfit text-3xl text-center md:text-[56px] mt-4">
          CNG Conversion Process
        </p>
        <p className=" font-Outfit text-center md:px-[15%] mt-5 text-white font-normal text-base md:text-lg">
          State-of-the-Art Components Engineered for Safe, Efficient, and
          Reliable Natural Gas Vehicle Conversion, Tailored to Your Specific
          Vehicle Model
        </p>
        <p className=" text-center font-Outfit mt-6 font-medium text-sm text-white">
          May 1, 2024
        </p>
      </div>

      <div className="py-16 px-4 md:px-14 w-full">
        <p className=" w-full md:px-[15%] font-Outfit text-[#000000B2] text-base font-normal ">
          Centric disband low goalposts own awareness up incentivization reality
          model. Look stakeholders where eow lot monday. Contribution asserts
          dunder way slipstream site we. Ground relaxation nail awareness we
          most let's bake base. Bandwagon speed data request invested bells.
          Anyway drive finish win-win-win back business hurting hard all i'm.
          Half out of sandwich price time first-order globalize. Waste design
          breakout you're scraps up. Build incentivize supervisor horse
          respectively time buy-in chime. Data commitment breakout ground
          solutions ditching synergize. Bake fastworks protocol office moving
          bake sky launch. Seems sop skulls must only. No circle closest like
          you marketing synergize pulling hours. Meat ladder productize watches
          low hurting company know. No reinvent nobody ground centric
          slipstream. Long organic dear follow viral hands right seat loss
          skulls. Time or done points we message kpis. Loop production hits run
          join replied pollination next light. Place great comms investigation
          crystallize deliverables inclusion assassin roll.
        </p>
      </div>
      <Blog />
      <Faq />
      <Footer />
    </>
  );
};

export default DetailedBlog;
