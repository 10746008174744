import { useState } from "react";
import arrowDown from "./assets/down.svg";

const Faq = () => {
  const faqsData = [
    {
      question: "What is CNG and how does it differ from petrol or diesel?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "How much can I save by converting my vehicle to CNG?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "Is CNG safe for my vehicle?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "How long does the CNG conversion process take?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "Will converting to CNG affect my vehicle's performance?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropDown = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Our FAQ
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Frequently asked questions
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          Get answers to common questions about CNG conversion, safety, cost
          savings, and more. Find everything you need to know before making the
          switch!
        </p>
        <div className=" w-full mt-16 space-y-4">
          {faqsData.map((faq, index) => (
            <div
              key={index}
              onClick={() => toggleDropDown(index)}
              className={`border py-3 px-6 w-full faq border-[#DAE0E6] rounded-[40px]${
                openIndex === index ? " active" : ""
              }`}
            >
              <div className="flex flex-row w-full justify-between items-center">
                <p className="font-Outfit text-[#000000] text-sm md:text-base lg:text-lg font-medium">
                  {faq.question}
                </p>
                <img
                  src={arrowDown}
                  className={`${
                    openIndex === index ? "transform rotate-180" : ""
                  } transition-transform duration-300`}
                  alt=""
                />
              </div>
              <div className={`answer${openIndex === index ? " open" : ""}`}>
                <p className="font-Outfit text-[#000000] font-normal text-sm md:text-base mt-5 transition-all duration-500">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
