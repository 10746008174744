import { useEffect, useRef, useState } from "react";
import xclose from "./assets/XClose button.svg";
import Additional from "./scheduleforms/additional";
import ConversionDate from "./scheduleforms/conversionDate";
import Location from "./scheduleforms/location";
import PersonalInfo from "./scheduleforms/personalInfo";
import VehicleInfo from "./scheduleforms/vehicleInfo";

const ScheduleMpdal = ({ setMakeVisible }) => {
  // State to manage which form step is currently active
  const [currentStep, setCurrentStep] = useState(0);
  // Ref to the scrollable div
  const containerRef = useRef(null);

  // Function to handle moving to the next step
  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Function to handle moving to the previous step
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Scroll to the top when currentStep changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0); // Scroll to the top
    }
  }, [currentStep]);

  // List of components in the form flow
  const steps = [
    <VehicleInfo handleNext={handleNext} handleBack={handleBack} />,
    <PersonalInfo handleNext={handleNext} handleBack={handleBack} />,
    <ConversionDate handleNext={handleNext} handleBack={handleBack} />,
    <Additional handleNext={handleNext} handleBack={handleBack} />,
    <Location handleNext={handleNext} handleBack={handleBack} />,
  ];
  return (
    <>
      <div className="w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] p-6 flex justify-center items-center">
        <div className=" md:ml-[20%] bg-[#FFFFFF] p-6 rounded-[15px] w-full h-[500px] 2xl:h-[700px] md:w-[700px]">
          <div className=" w-full h-full overflow-auto ">
            <div className=" w-full flex items-start justify-between">
              <span>
                <p className=" font-Outfit text-[#272D37] font-bold text-3xl ">
                  CNG Conversion Request
                </p>
                <p className=" text-[#272D37] font-Outfit font-normal text-base mt-2">
                  Fill out the form below to request conversion from
                  petrol/diesel to CNG
                </p>
              </span>
              <img
                onClick={() => {
                  setMakeVisible(false);
                }}
                src={xclose}
                className=" "
                alt=""
              />
            </div>
            {/* Show the current step */}
            {steps[currentStep]}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleMpdal;
