import { useState } from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import cover from "./assets/cover.svg";
import arrowDown from "./assets/down.svg";

const Faqs = () => {
  const faqsData = [
    {
      question: "What is CNG and how does it differ from petrol or diesel?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "How much can I save by converting my vehicle to CNG?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "Is CNG safe for my vehicle?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "How long does the CNG conversion process take?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      question: "Will converting to CNG affect my vehicle's performance?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropDown = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundPosition: "",
          backgroundSize: "cover",
        }}
        className=" w-full h-[350px] flex items-center flex-col justify-center px-4 md:px-14"
      >
        <p className=" text-white font-bold font-Outfit text-3xl text-center md:text-[56px]">
          Frequently Asked Questions
        </p>
        <p className=" font-Outfit text-center md:px-[15%] mt-5 text-white font-normal text-base md:text-lg">
          Find answers to the most common questions about our products,
          services, and policies. Whether you're curious about how things work,
          need help troubleshooting an issue, or just want to understand more
          about what we do, our FAQ section has the information you’re looking
          for.
        </p>
      </div>
      <div className="w-full py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <div className=" w-full space-y-4">
          {faqsData.map((faq, index) => (
            <div
              key={index}
              onClick={() => toggleDropDown(index)}
              className={`border py-3 px-6 w-full faq border-[#DAE0E6] rounded-[40px]${
                openIndex === index ? " active" : ""
              }`}
            >
              <div className="flex flex-row w-full justify-between items-center">
                <p className="font-Outfit text-[#000000] text-sm md:text-base lg:text-lg font-medium">
                  {faq.question}
                </p>
                <img
                  src={arrowDown}
                  className={`${
                    openIndex === index ? "transform rotate-180" : ""
                  } transition-transform duration-300`}
                  alt=""
                />
              </div>
              <div className={`answer${openIndex === index ? " open" : ""}`}>
                <p className="font-Outfit text-[#000000] font-normal text-sm md:text-base mt-5 transition-all duration-500">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faqs;
