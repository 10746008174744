import stepper from "./assets/Stepper.svg";

const VehicleInfo = ({ handleNext, handleBack }) => {
  return (
    <>
      <img src={stepper} className=" w-full mt-4 hidden md:block" alt="" />
      <p className=" font-Outfit font-bold text-[#000] text-2xl mt-3">
        Vehicle Information
      </p>
      <div className="mt-6 w-full flex items-center flex-col md:flex-row justify-between">
        <label
          htmlFor="make"
          className=" w-full md:w-[48%] flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
        >
          Vehicle Make
          <input
            name="make"
            id="make"
            placeholder="Enter Vehicle Make"
            className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
          />
          <p className="text-[#5F6D7E] text-sm font-normal mt-1 font-Outfit">
            (e.g., Toyota, Honda)
          </p>
        </label>

        <label
          htmlFor="model"
          className=" w-full md:w-[48%] mt-6 md:mt-0 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
        >
          Vehicle Model
          <input
            name="model"
            id="model"
            placeholder="Enter Vehicle Model"
            className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
          />
          <p className="text-[#5F6D7E] text-sm font-normal mt-1 font-Outfit">
            (e.g., Corolla, Civic)
          </p>
        </label>
      </div>
      <div className="mt-4 w-full flex items-center flex-col md:flex-row justify-between">
        <label
          htmlFor="year"
          className=" w-full md:w-[48%] flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
        >
          Year of Manufacture
          <input
            name="year"
            id="year"
            placeholder="Enter Year of Manufacture"
            className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
          />
        </label>

        <label
          htmlFor="model"
          className=" w-full md:w-[48%] mt-6 md:mt-0 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
        >
          Vehicle Identification Number (VIN)
          <input
            name="model"
            id="model"
            placeholder="Enter Vehicle Identification Number (VIN)"
            className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
          />
        </label>
      </div>

      <label
        htmlFor="make"
        className="w-full mt-4 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
      >
        Engine Type
        <span className="w-full mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3">
          <select
            name="type"
            id="type"
            className="w-full text-[#919BA7] font-normal font-Outfit text-sm"
          >
            <option value="">Choose Engine Type</option>
            <option value="Petrol">Petrol</option>
            <option value="Diesel">Diesel</option>
          </select>
        </span>
        <p className="text-[#5F6D7E] text-sm font-normal mt-1 font-Outfit">
          (e.g., Petrol, Diesel)
        </p>
      </label>

      <label
        htmlFor="make"
        className="w-full mt-4 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
      >
        Preferred Conversion Type
        <span className="w-full mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3">
          <select
            name="type"
            id="type"
            className="w-full text-[#919BA7] font-normal font-Outfit text-sm"
          >
            <option value="">Choose Conversion Type</option>
            <option value=" Bi-Fuel"> Bi-Fuel</option>
            <option value="Dedicated CNG">Dedicated CNG</option>
          </select>
        </span>
        <p className="text-[#5F6D7E] text-sm font-normal mt-1 font-Outfit">
          (e.g., Bi-Fuel (CNG + Petrol/Diesel, Dedicated CNG (CNG only))
        </p>
      </label>
      <div className="grid grid-cols-2 gap-4 pt-6 pb-4">
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#272D37] font-semibold border border-[#DAE0E6] text-base`}
          onClick={handleBack} // Call handleBack when the Back button is clicked
        >
          Back
        </button>
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#fff] bg-[#096F00] font-semibold flex justify-center items-center text-base`}
          onClick={handleNext} // Call handleNext when the Next button is clicked
        >
          Next
        </button>
      </div>
    </>
  );
};

export default VehicleInfo;
