import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Landing from "./pages/landing";
import Faqs from "./pages/faqs";
import Blog from "./pages/blog";
import About from "./pages/about";
import Contact from "./pages/contact";
import DetailedBlog from "./pages/detailedBlog";
import CNGKits from "./pages/cngKits";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/faq" element={<Faqs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/detailedblog" element={<DetailedBlog />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cngkits" element={<CNGKits />} />
      </Routes>
    </>
  );
}

export default App;
