import { Link } from "react-router-dom";
import logo from "./assets/logow.svg";
import phone from "./assets/phone.svg";
import mail from "./assets/mail.svg";
import ig from "./assets/ig.svg";
import fb from "./assets/fb.svg";

const Footer = () => {
  return (
    <>
      <div className=" bg-[#043500] px-4 md:px-14 ">
        {/* 1 */}
        <div className=" w-full py-8 space-y-8 md:space-y-0 flex flex-col md:flex-row items-start md:items-center md:justify-between border-b border-[#FFFFFF4D]">
          <div className=" space-y-3">
            <p className=" font-Outfit text-white font-bold text-[40px] leading-[48px]">
              Convert Your Vehicle to CNG
            </p>
            <p className=" font-Outfit text-lg font-normal text-white">
              Experience the Benefits of CNG Conversion Today
            </p>
          </div>
          <button className="px-5 py-3 mt-8 font-Outfit text-base rounded-[40px] font-medium shadow hover:shadow-[#007272] transition-all text-[#096F00] bg-white">
            Get Started Now
          </button>
        </div>

        {/* 2 */}
        <div className=" w-full py-8 md:py-16 flex flex-col lg:flex-row items-start space-y-6 lg:space-y-0 md:justify-between  border-b border-[#FFFFFF4D]">
          <div className=" w-full md:w-[200px]">
            <img src={logo} alt="" />
            <p className=" mt-4 font-Outfit font-normal text-[16px] text-[#FFFFFFB2]">
              Generate outside the box thinking with the possibility to targtet
              the low.
            </p>
          </div>

          <div className=" w-full md:w-[200px]">
            <p className=" font-Outfit font-semibold text-base text-[#f9f9f9] mb-4">
              Company
            </p>
            <div className=" flex flex-col space-y-3">
              <Link
                to="/about"
                className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]"
              >
                About us
              </Link>
              <Link className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]">
                Leadership
              </Link>
              <Link className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]">
                Partnership
              </Link>
              <Link className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]">
                Career
              </Link>
            </div>
          </div>

          <div className=" w-full md:w-[200px]">
            <p className=" font-Outfit font-semibold text-base text-[#f9f9f9] mb-4">
              Services
            </p>
            <div className=" flex flex-col space-y-3">
              <Link
                to="/cngkits"
                className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]"
              >
                CNG Kit Installation
              </Link>
              <Link className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]">
                Maintenance
              </Link>
              <Link className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]">
                Consultancy
              </Link>
              <Link className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]">
                CNG Kits Shop
              </Link>
            </div>
          </div>

          <div className=" w-full md:w-[200px]">
            <p className=" font-Outfit font-semibold text-base text-[#f9f9f9] mb-4">
              Support
            </p>
            <div className=" flex flex-col space-y-3">
              <Link
                to="/blog"
                className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]"
              >
                Blog
              </Link>
              <Link
                to="/faq"
                className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]"
              >
                FAQ
              </Link>
              <Link
                to="/contact"
                className=" text-[#FFFFFFB2] font-Outfit font-normal text-[16px]"
              >
                Contact Us
              </Link>
            </div>
          </div>

          <div className=" w-full md:w-[200px]">
            <p className=" font-Outfit font-semibold text-base text-[#f9f9f9] mb-4">
              Get in touch
            </p>
            <div className=" flex flex-col space-y-3">
              <p className=" mt-1 font-Outfit font-normal text-[16px] text-[#FFFFFFB2]">
                Bold Moves Hub, off Admiralty Road lekki phase 1.
                Victoria Island Lagos.
              </p>
              <span className=" flex items-center space-x-3 mt-3">
                <img src={mail} alt="" />
                <p className=" font-Outfit font-normal text-[16px] text-[#FFFFFFB2]">
                  info@dayrunnerenergy.com
                </p>
              </span>
              <span className=" flex items-center space-x-3 mt-3">
                <img src={phone} alt="" />
                <p className=" font-Outfit font-normal text-[16px] text-[#FFFFFFB2]">
                  +234(0)8144434800
                </p>
              </span>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className=" w-full flex justify-between items-center text-white font-Outfit font-normal text-base py-8 md:py-12">
          <p className=" text-[#FFFFFFB2] font-Outfit text-base font-normal">
            © 2024 Day runner
          </p>
          <span className=" space-x-4 flex items-center">
            <img src={fb} alt="" />
            <img src={ig} alt="" />
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
