import { Link } from "react-router-dom";
import logo from "./assets/logo.svg";
import { useState } from "react";
import ScheduleMpdal from "./scheduleModal";

const Navbar = () => {
  function overlay() {
    //check classlist
    const overlayDiv = document.getElementById("overlay");
    if (overlayDiv.classList.contains("-translate-y-[500px]")) {
      overlayDiv.classList.remove("-translate-y-[500px]");
    } else if (!overlayDiv.classList.contains("-translate-y-[500px]")) {
      overlayDiv.classList.add("-translate-y-[500px]");
    }
  }

  const [makeVisible, setMakeVisible] = useState(false);

  return (
    <>
      {makeVisible && <ScheduleMpdal setMakeVisible={setMakeVisible} />}
      <div
        id="overlay"
        className=" w-full bg-[#fff] backdrop-blur-xl h-[350px] p-6 flex justify-center items-center -translate-y-[500px] shadow transition-all duration-700 top-[78px] fixed z-[99]"
      >
        <div className="w-full flex flex-col justify-center items-center space-y-5">
          <Link to="/">
            <p className=" font-Outfit text-[15px] font-normal text-[#000]">
              Home
            </p>
          </Link>

          <Link to="/about">
            <p className=" font-Outfit text-[15px] font-normal text-[#000]">
              About Us
            </p>
          </Link>
          <Link to="/cngkits">
            <p className=" font-Outfit text-[15px] font-normal text-[#000]">
              CNG Kits
            </p>
          </Link>
          <Link to="/faq">
            <p className=" font-Outfit text-[15px] font-normal text-[#000]">
              FAQ
            </p>
          </Link>
          <Link to="/blog">
            <p className=" font-Outfit text-[15px] font-normal text-[#000]">
              Blog
            </p>
          </Link>
          <Link to="/contact">
            <p className=" font-Outfit text-[15px] font-normal text-[#000]">
              Contact Us
            </p>
          </Link>
          <div className=" flex md:hidden z-50 items-center  mt-6">
            <>
              <button
                onClick={() => {
                  setMakeVisible(true);
                }}
                className="px-5 py-3 font-Outfit text-base rounded-[40px] font-medium shadow hover:shadow-[#007272] transition-all bg-[#096F00] text-white"
              >
                Schedule a calendar
              </button>
            </>
          </div>
        </div>
      </div>

      <div className=" py-5 px-4 md:px-14 flex items-center justify-between border-b border-[#EAEBF0]">
        <div className=" flex items-center space-x-16">
          <img src={logo} className=" w-auto" alt="" />
          <div className=" hidden lg:flex items-center space-x-6">
            <Link
              to="/"
              className=" text-[#000] font-Outfit font-normal text-[15px]"
            >
              Home
            </Link>
            <Link
              to="/about"
              className=" text-[#000] font-Outfit font-normal text-[15px]"
            >
              About Us
            </Link>
            <Link
              to="/cngkits"
              className=" text-[#000] font-Outfit font-normal text-[15px]"
            >
              CNG Kits
            </Link>
            <Link
              to="/faq"
              className=" text-[#000] font-Outfit font-normal text-[15px]"
            >
              FAQ
            </Link>
            <Link
              to="/blog"
              className=" text-[#000] font-Outfit font-normal text-[15px]"
            >
              Blog
            </Link>
            <Link
              to="/contact"
              className=" text-[#000] font-Outfit font-normal text-[15px]"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className=" flex items-center space-x-5">
          <div className=" hidden md:flex items-center">
            <button
              onClick={() => {
                setMakeVisible(true);
              }}
              style={{ boxShadow: "0px 1px 2px 0px #1018280A" }}
              className=" bg-[#096F00] py-3 px-[18px] rounded-[40px] text-white font-Outfit font-medium text-[15px]"
            >
              Schedule a calendar
            </button>
          </div>
          <div onClick={overlay} className="menu-icon md:block lg:hidden">
            <input className="menu-icon__cheeckbox" type="checkbox" />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
