import Blog from "../components/blog";
import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import cover from "./assets/cover1.svg";
import gasvalve from "./assets/gasvalve.svg";
import assembly from "./assets/assembly.svg";
import regulator from "./assets/regulatorsgi.svg";
import fastInject from "./assets/goldinject.svg";
import hyg from "./assets/Hygiene-Filter-1920w.svg";
import ecu from "./assets/930-gold-ecu-1920w.svg";
import gwire from "./assets/918-sc24-kablo-tesisati-1920w.svg";
import orgb from "./assets/dsc_7672-263a7452-1920w.svg";
import sensor from "./assets/919-sc48-map-sensoru-770d0e32-1920w.svg";
import manmo from "./assets/730+CNG+Manometer-a1d8eccf-1920w.svg";
import mfil from "./assets/710+CNG+Gas+Filter-3d835f52-1920w.svg";
import temps from "./assets/921-a18b6e5a-1920w.svg";
import gval from "./assets/902U+Gas+Value-1920w.svg";
import tightbox from "./assets/tightbox.svg";
import gv from "./assets/902+Gas+Valve-1920w.svg";
import fu from "./assets/904+Filling+Unit-1920w.svg";
import ls from "./assets/Level+Sensor-1920w.svg";
import multival from "./assets/multivalve.svg";
import sgi from "./assets/regulator.svg";
import cngset from "./assets/CNG+Truck+-+Gen-set-2880w.svg";
import regul from "./assets/700S+Hot+Regulator-01712009-1920w.svg";
import fnozz from "./assets/770+CNG+FillingnNozzle-1920w.svg";
import nozzle from "./assets/771+CNG+Filling+Nozzle-1920w.svg";
import filt from "./assets/917+Filter+12x12x12-1920w.svg";
import cnv from "./assets/6+to+8mm+Convertor-1920w.svg";
import velb from "./assets/6mm+Multi-Value+Elbow-1920w.svg";
import nut from "./assets/8mm+Nut-1920w.svg";
import pvlve from "./assets/16x12x12+Plastic+Valve-1920w.svg";
import mvlve from "./assets/metVave.svg";
import brac from "./assets/902+Bracket-1920w.svg";

const CNGKits = () => {
  const kits1 = [
    { name: "ASSEMBLY SET", img: assembly },
    { name: "CNG SGI REGULATOR", img: regulator },
    { name: "CNG GAS VALVE", img: gasvalve },
    { name: "FAST INJECTOR 2ohms", img: fastInject },
    { name: "HYGIENE FILTER", img: hyg },
    { name: "GOLD ELECTRONIC CONTROL UNIT (ECU)", img: ecu },
    { name: "GOLD WIRE HARENESS", img: gwire },
    { name: "OLD RGB FUEL SELETOR SWITCH", img: orgb },
    { name: "GOLD MAP SENSOR", img: sensor },
    { name: "MANMOMETRE", img: manmo },
    { name: "MIDDLE FILTER", img: mfil },
    { name: "TEMPERATURE SENSOR REGULATOR", img: temps },
    {
      name: "6*12/180cm; 12*18/120 cm; \n 4*9/180 cm 5*11/120 cm HOSE",
      img: gval,
    },
  ];
  const kits2 = [
    { name: "GAS VALVE", img: gv },
    { name: "FILLING UNIT", img: fu },
    { name: "AIR TIGHT BOX", img: tightbox },
    { name: "LEVEL INDICATOR SENSOR", img: ls },
    { name: "ASSEMBLY SET", img: assembly },
    { name: "MULTIVALUE", img: multival },
    { name: "SGI REGULATOR", img: sgi },
    { name: "HYGIENE FILTER", img: hyg },
    { name: "GOLD WIRE HARENESS", img: gwire },
    { name: "GOLD MAP SENSOR", img: sensor },
    { name: "TEMPERATURE SENSOR REGULATOR", img: temps },
    { name: "OLD RGB FUEL SELETOR SWITCH", img: orgb },
    { name: "GOLD ELECTRONIC CONTROL UNIT (ECU)", img: ecu },
    { name: "FAST INJECTOR 2ohms", img: fastInject },
    {
      name: "6*12/180cm; 12*18/120 cm; \n Air -Conditioning Hose 150 cm/4*9/180 cm 5*11/120 cm HOSE",
      img: multival,
    },
  ];

  const kits3 = [
    { name: "REGULATOR/REDUCER", img: regul },
    { name: "GAS VALVE", img: mfil },
    { name: "MANMOMETRE", img: manmo },
    { name: "FILLING NOZZLE", img: fnozz },
    { name: "FILLING NOZZLE", img: nozzle },
    { name: "12X12X12 FILTER", img: filt },
  ];
  const kits4 = [
    { name: "6 to 8mm CONVERTOR", img: cnv },
    { name: "6mm MULTI-VALVE ELBOW", img: velb },
    { name: "8mm MULTI-VALVE ELBOW", img: velb },
    { name: "8mm NUT", img: nut },
    { name: "16X12X12 PLASTIC VALVE", img: pvlve },
    { name: "16X16 METAL VALVE", img: mvlve },
    { name: "BRACKETS", img: brac },
  ];

  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundPosition: "",
          backgroundSize: "cover",
        }}
        className=" w-full h-[500px] flex items-center flex-col justify-center px-4 md:px-14"
      >
        <p className=" text-white font-bold font-Outfit text-3xl text-center md:text-[56px]">
          CNG Conversion Kits
        </p>
        <p className=" font-Outfit text-center md:px-[15%] mt-5 text-white font-normal text-base md:text-lg">
          State-of-the-Art Components Engineered for Safe, Efficient, and
          Reliable Natural Gas Vehicle Conversion, Tailored to Your Specific
          Vehicle Model
        </p>
        <button className=" mt-8 py-3 px-6 bg-[#096F00] rounded-[40px] text-white font-Outfit font-semibold text-sm md:text-[15px]">
          Book a conversion
        </button>
      </div>
      {/* Sequential Fuel Injection Systems kits */}
      <div className=" w-full py-16 px-4 md:px-14">
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[15%] md:leading-[58px] md:text-[48px]">
          Sequential Fuel Injection Systems kits
        </p>
        <div className=" mt-16 w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          {kits1.map((item, index) => (
            <div key={index} className=" w-full">
              <div className=" w-full p-12 border border-[#F0EEED] bg-white rounded-[12px] flex items-center justify-center">
                <img src={item.img} className="" alt="" />
              </div>
              <p className=" mt-4 font-Outfit font-medium text-black text-base md:text-xl">
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* LPG Sequential Fuel Injection System Kits */}
      <div className=" w-full py-8 md:py-16 px-4 md:px-14">
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[15%] md:leading-[58px] md:text-[48px]">
          LPG Sequential Fuel Injection System Kits
        </p>
        <div className=" mt-16 w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          {kits2.map((item, index) => (
            <div key={index} className=" w-full">
              <div className=" w-full p-12 border border-[#F0EEED] bg-white rounded-[12px] flex items-center justify-center">
                <img src={item.img} className="" alt="" />
              </div>
              <p className=" mt-4 font-Outfit font-medium text-black text-base md:text-xl">
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* 100kva Generator and above, kit for Trucks and more. */}
      <div className=" w-full py-8 md:py-16 px-4 md:px-14">
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[15%] md:leading-[58px] md:text-[48px]">
          100kva Generator and above, kit for Trucks and more.
        </p>
        <div className=" w-full md:px-[15%]">
          <div
            style={{
              backgroundImage: `url(${cngset})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" w-full mt-16 h-[450px] rounded-[40px] bg-[#00000031]"
          ></div>
        </div>
        <p className=" mt-4 text-center font-Outfit font-bold text-black text-2xl">
          Starting from N6.7 million (subject to FX Rate)
        </p>
      </div>
      {/* CNG SPARES */}
      <div className=" w-full py-8 md:py-16 px-4 md:px-14">
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[15%] md:leading-[58px] md:text-[48px]">
          CNG SPARES
        </p>
        <div className=" mt-16 w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          {kits3.map((item, index) => (
            <div key={index} className=" w-full">
              <div className=" w-full p-12 border border-[#F0EEED] bg-white rounded-[12px] flex items-center justify-center">
                <img src={item.img} className="" alt="" />
              </div>
              <p className=" mt-4 font-Outfit font-medium text-black text-base md:text-xl">
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* LNG SPARES */}
      <div className=" w-full py-8 md:py-16 px-4 md:px-14">
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[15%] md:leading-[58px] md:text-[48px]">
          LNG SPARES
        </p>
        <div className=" mt-16 w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          {kits4.map((item, index) => (
            <div key={index} className=" w-full">
              <div className=" w-full p-12 border border-[#F0EEED] bg-white rounded-[12px] flex items-center justify-center">
                <img src={item.img} className="" alt="" />
              </div>
              <p className=" mt-4 font-Outfit font-medium text-black text-base md:text-xl">
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Blog />
      <Faq />
      <Footer />
    </>
  );
};

export default CNGKits;
