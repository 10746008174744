import blog1 from "./assets/blog1.svg";
import blog2 from "./assets/blog2.svg";
import blog3 from "./assets/blog3.svg";
import right from "./assets/right.svg";

const Blog = () => {
  const blogs = [
    {
      name: "The Benefits of CNG Conversion",
      tag: "Conversion",
      desc: "Discover the advantages of converting your vehicle to CNG fuel",
      duration: "3 min read",
      image: blog1,
    },
    {
      name: "Top CNG Maintenance Tips",
      tag: "Maintenance",
      desc: "Learn how to keep your CNG vehicle running smoothly",
      duration: "4 min read",
      image: blog2,
    },
    {
      name: "Latest CNG Industry News",
      tag: "Industry",
      desc: "Stay updated on the latest trends and developments in the CNG industry",
      duration: "5 min read",
      image: blog3,
    },
  ];
  return (
    <>
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Our Latest Blog
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Stay Informed about CNG
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] px-[10%] text-center">
          Get the latest news, tips, and updates on CNG conversion
        </p>
        <div className=" w-full mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogs.map((item, index) => (
            <div key={index} className=" w-full">
              <div
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className=" w-full h-[300px] rounded-[20px] bg-[#00000031]"
              ></div>
              <div className=" mt-6 flex flex-row items-center space-x-4">
                <button className=" rounded-[20px] bg-[#096F001A] px-3 py-1 text-center font-Outfit font-semibold text-sm text-black">
                  {item.tag}
                </button>
                <p className="font-Outfit font-semibold text-sm text-black">
                  {item.duration}
                </p>
              </div>
              <p className=" mt-6 font-Outfit font-bold  text-2xl text-black">
                {item.name}
              </p>
              <p className=" mt-2 font-Outfit font-normal  text-base text-black">
                {item.desc}
              </p>
              <span className=" mt-7 space-x-5 flex items-center">
                <p className=" text-[#096F00] font-normal font-Outfit text-base">
                  Read more
                </p>
                <img src={right} alt="" />
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blog;
