import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import cover from "./assets/cover.svg";
import mail from "./assets/mail.svg";
import phone from "./assets/phone.svg";
import fb from "./assets/fb.svg";
import twitter from "./assets/twitter.svg";
import linkedin from "./assets/linkedin.svg";

const Contact = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundPosition: "",
          backgroundSize: "cover",
        }}
        className=" w-full h-[350px] flex items-center flex-col justify-center px-4 md:px-14"
      >
        <p className=" text-white font-bold font-Outfit text-3xl text-center md:text-[56px]">
          Contact Us
        </p>
        <p className=" font-Outfit text-center md:px-[15%] mt-5 text-white font-normal text-base md:text-lg">
          We’re here to help with any questions, concerns, or feedback you may
          have. Whether you need assistance with our services or just want to
          know more about what we offer, feel free to reach out to us, and our
          team will be more than happy to assist you in any way possible.
        </p>
      </div>
      <div className="w-full py-16 px-4 md:px-14 flex flex-col space-y-8 md:space-y-0 md:flex-row  items-start justify-between">
        <div className=" w-full md:w-[63%]">
          <div className="w-full flex items-center flex-col md:flex-row justify-between">
            <label
              htmlFor="name"
              className=" w-full md:w-[48%] flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
            >
              Full Name
              <input
                name="name"
                id="name"
                placeholder="Enter Full Name"
                className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
              />
            </label>

            <label
              htmlFor="mail"
              className=" w-full md:w-[48%] mt-6 md:mt-0 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
            >
              Email address
              <input
                name="mail"
                id="mail"
                placeholder="Enter Email address"
                className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
              />
            </label>
          </div>

          <label
            htmlFor="phone"
            className=" w-full md:w-full mt-6 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
          >
            Phone Number
            <input
              name="phone"
              id="phone"
              placeholder="Enter Phone Number"
              className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
            />
          </label>

          <label
            htmlFor="message"
            className=" w-full md:w-full mt-6 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
          >
            Message
            <textarea
              name="message"
              id="message"
              placeholder="Enter Message"
              className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
            ></textarea>
          </label>

          <span className=" mt-6 space-x-3 flex items-center">
            <input
              type="checkbox"
              name=""
              className=" border-2 border-[#DAE0E6] rounded-[5px]"
              id=""
            />
            <p className=" font-Outfit text-[#272D37] font-medium text-base">
              I agree with Dayrunner Privacy Policy
            </p>
          </span>

          <button className=" px-6 py-3 bg-[#096F00] rounded-[40px] mt-6 text-white font-Outfit text-center font-semibold text-[15px]">
            Submit
          </button>
        </div>
        <div className=" w-full md:w-[33%]">
          <p className=" text-[#272D37] font-Outfit font-medium text-lg">
            Address
          </p>
          <p className=" mt-4 font-Outfit font-normal text-base text-[#5F6D7E]">
            Bold Moves Hub, off Admiralty Road lekki phase 1.
            Victoria Island Lagos.
          </p>
          <span className=" flex items-center mt-2 space-x-3">
            <img src={mail} alt="" />
            <p className=" font-Outfit text-[#096F00] font-medium text-[15px]">
              info@dayrunnerenergy.com
            </p>
          </span>
          <span className=" flex items-center mt-2 space-x-3">
            <img src={phone} alt="" />
            <p className=" font-Outfit text-[#096F00] font-medium text-[15px]">
              +234(0)8144434800
            </p>
          </span>
          <p className=" text-[#272D37] font-Outfit font-medium text-lg mt-6">
            Social media
          </p>
          <div className=" space-x-2 mt-2 flex items-center">
            <span className=" p-2 border border-[#DAE0E6] rounded-[5px] flex items-center justify-center">
              <img src={fb} alt="" />
            </span>
            <span className=" p-2 border border-[#DAE0E6] rounded-[5px] flex items-center justify-center">
              <img src={twitter} alt="" />
            </span>
            <span className=" p-2 border border-[#DAE0E6] rounded-[5px] flex items-center justify-center">
              <img src={linkedin} alt="" />
            </span>
          </div>
        </div>
      </div>
      <Faq />
      <Footer />
    </>
  );
};

export default Contact;
