import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import cover from "./assets/cover.svg";
import blog1 from "./assets/blog1.svg";
import blog2 from "./assets/blog2.svg";
import blog3 from "./assets/blog3.svg";
import right from "./assets/right.svg";

const Blog = () => {
  const blogs = [
    {
      name: "The Benefits of CNG Conversion",
      tag: "Conversion",
      desc: "Discover the advantages of converting your vehicle to CNG fuel",
      duration: "3 min read",
      image: blog1,
    },
    {
      name: "Top CNG Maintenance Tips",
      tag: "Maintenance",
      desc: "Learn how to keep your CNG vehicle running smoothly",
      duration: "4 min read",
      image: blog2,
    },
    {
      name: "Latest CNG Industry News",
      tag: "Industry",
      desc: "Stay updated on the latest trends and developments in the CNG industry",
      duration: "5 min read",
      image: blog3,
    },
  ];
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundPosition: "",
          backgroundSize: "cover",
        }}
        className=" w-full h-[350px] flex items-center flex-col justify-center px-4 md:px-14"
      >
        <p className=" text-white font-bold font-Outfit text-3xl text-center md:text-[56px]">
          Our Latest Blog
        </p>
        <p className=" font-Outfit text-center md:px-[15%] mt-5 text-white font-normal text-base md:text-lg">
          Dive into our collection of articles, industry news, and
          thought-provoking stories designed to keep you informed and engaged.
          From expert advice and practical tips to the latest updates and
          trends, our blog is your go-to resource for everything you need to
          stay ahead.
        </p>
      </div>
      <div className="w-full py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <div className=" w-full grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogs.map((item, index) => (
            <div key={index} className=" w-full">
              <div
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className=" w-full h-[300px] rounded-[20px] bg-[#00000031]"
              ></div>
              <div className=" mt-6 flex flex-row items-center space-x-4">
                <button className=" rounded-[20px] bg-[#096F001A] px-3 py-1 text-center font-Outfit font-semibold text-sm text-black">
                  {item.tag}
                </button>
                <p className="font-Outfit font-semibold text-sm text-black">
                  {item.duration}
                </p>
              </div>
              <p className=" mt-6 font-Outfit font-bold  text-2xl text-black">
                {item.name}
              </p>
              <p className=" mt-2 font-Outfit font-normal  text-base text-black">
                {item.desc}
              </p>
              <span className=" mt-7 space-x-5 flex items-center">
                <p className=" text-[#096F00] font-normal font-Outfit text-base">
                  Read more
                </p>
                <img src={right} alt="" />
              </span>
            </div>
          ))}
        </div>
      </div>
      <Faq />
      <Footer />
    </>
  );
};

export default Blog;
