import stepper from "./assets/Stepper1.svg";

const PersonalInfo = ({ handleBack, handleNext }) => {
  return (
    <>
      <img src={stepper} className=" w-full mt-4 hidden md:block" alt="" />
      <p className=" font-Outfit font-bold text-[#000] text-2xl mt-3">
        Personal Information
      </p>

      <label
        htmlFor="name"
        className=" w-full mt-6 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
      >
        Full Name
        <input
          name="name"
          id="name"
          placeholder="Enter Full Name"
          className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
        />
      </label>
      <label
        htmlFor="email"
        className=" w-full mt-4 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
      >
        Email Address
        <input
          name="email"
          id="email"
          placeholder="Enter Email Address"
          className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
        />
      </label>
      <label
        htmlFor="phone"
        className=" w-full mt-4 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
      >
        Phone Number
        <input
          name="phone"
          id="phone"
          placeholder="Enter Phone Number"
          className="w-full text-[#919BA7] font-normal font-Outfit text-sm mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3"
        />
      </label>
      <div className="grid grid-cols-2 gap-4 pt-6 pb-4">
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#272D37] font-semibold border border-[#DAE0E6] text-base`}
          onClick={handleBack} // Call handleBack when the Back button is clicked
        >
          Back
        </button>
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#fff] bg-[#096F00] font-semibold flex justify-center items-center text-base`}
          onClick={handleNext} // Call handleNext when the Next button is clicked
        >
          Next
        </button>
      </div>
    </>
  );
};

export default PersonalInfo;
