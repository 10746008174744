import { useState } from "react";
import stepper from "./assets/Stepper3.svg";

const Additional = ({ handleBack, handleNext }) => {
  const [option, setOption] = useState("");

  const services = [
    {
      name: "CNG Kit Maintenance Package",
      price: "$2,000",
      desc: "Regular maintenance to ensure optimal performance and longevity of your CNG kit.",
    },
    {
      name: "Annual Inspection and Tune-Up",
      price: "$2,000",
      desc: "Comprehensive inspections and tune-ups to maintain top efficiency and safety of your CNG system.",
    },
  ];
  return (
    <>
      <img src={stepper} className=" w-full mt-4 hidden md:block" alt="" />
      <p className=" font-Outfit font-bold text-[#000] text-2xl mt-3">
        Additional Services (Optional)
      </p>

      <div className="mt-6">
        {services.map((item) => (
          <div
            key={item.id}
            className={`p-4 mb-3 rounded-lg flex flex-row justify-between items-start cursor-pointer ${
              option === item.name
                ? "border-[#096F00] border-2"
                : "border-[#EAEBF0] border"
            }`}
            // onClick={() => handleOptionSelection(item)}
          >
            <div className=" flex items-start space-x-4">
              <div className="round">
                <input
                  type="checkbox"
                  id={item.name}
                  checked={option === item.name}
                  readOnly
                />
                <label htmlFor={item.name}></label>
              </div>
              <span className=" -mt-1">
                <p
                  className={`font-Outfit text-base font-medium ${
                    option === item.name ? "text-[#096F00]" : "text-[#272D37]"
                  }`}
                >
                  {item.name}
                </p>
                <p
                  className={`font-Outfit font-normal text-sm ${
                    option === item.name ? "text-[#096F00]" : "text-[#5F6D7E]"
                  }`}
                >
                  {item.desc}
                </p>
              </span>
            </div>
            <p className=" text-sm text-[#096F00] font-Outfit font-normal">
              {item.price}
            </p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-4 pt-6 pb-4">
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#272D37] font-semibold border border-[#DAE0E6] text-base`}
          onClick={handleBack} // Call handleBack when the Back button is clicked
        >
          Back
        </button>
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#fff] bg-[#096F00] font-semibold flex justify-center items-center text-base`}
          onClick={handleNext} // Call handleNext when the Next button is clicked
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Additional;
