import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import cover from "./assets/cover.svg";
import bene1 from "./assets/bene1.svg";
import bene2 from "./assets/pikaso_embed.svg";
import bene3 from "./assets/2149290463.svg";
import miss from "./assets/mission.svg";
import vision from "./assets/vision.svg";

const About = () => {
  const states = [
    {
      name: "Our Mission",
      desc: "To make clean and affordable energy accessible to all vehicle and generator owners in Nigeria by providing a rapid, cost-effective, and scalable CNG conversion service.",
      img: miss,
    },
    {
      name: "Our Vision",
      desc: "To be Nigeria’s leading CNG conversion service provider, enabling a sustainable and affordable transition to clean energy for transportation and industrial power, reaching a turnover of USD 100 million within the first year of operations.",
      img: vision,
    },
  ];

  const team = [
    { name: "CNG GAS VALVE", img: "" },
    { name: "CNG GAS VALVE", img: "" },
    { name: "CNG GAS VALVE", img: "" },
    { name: "CNG GAS VALVE", img: "" },
    { name: "CNG GAS VALVE", img: "" },
    { name: "CNG GAS VALVE", img: "" },
    { name: "CNG GAS VALVE", img: "" },
    { name: "CNG GAS VALVE", img: "" },
  ];
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundPosition: "",
          backgroundSize: "cover",
        }}
        className=" w-full h-[350px] flex items-center flex-col justify-center px-4 md:px-14"
      >
        <p className=" text-white font-bold font-Outfit text-3xl text-center md:text-[56px]">
          About Us
        </p>
        <p className=" font-Outfit text-center md:px-[15%] mt-5 text-white font-normal text-base md:text-lg">
          Discover the story behind our company, our values, and the passion
          that drives us to deliver exceptional products and services. Learn
          about our mission, our journey, and the dedicated team working hard to
          make a difference every day. We believe in building lasting
          relationships with our customers, and we’re excited to share our story
          with you.
        </p>
      </div>
      {/* our story */}
      <div className="w-full py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Our Story
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Our Journey to Cleaner Energy Solution
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          From our humble beginnings to becoming a leader in CNG vehicle
          conversions, our story is driven by a commitment to reducing
          emissions, lowering fuel costs, and creating a sustainable future for
          everyone. Discover how our passion for eco-friendly innovation has
          transformed the way vehicles run, one conversion at a time.
        </p>

        {/* benefit flex box 1*/}
        <div className=" w-full mt-16 flex flex-col-reverse md:flex-row items-center justify-between">
          <div className=" w-full md:w-[48%] mt-8 md:mt-0">
            <p className=" font-Outfit text-2xl md:text-3xl md:leading-[41px] font-bold text-black">
              How We Started
            </p>
            <p className=" text-base md:text-lg font-normal font-Outfit text-black mt-6">
              Our journey began with a simple yet powerful mission: to reduce
              the environmental impact of petrol-powered vehicles. As a small
              team of automotive enthusiasts with a passion for sustainability,
              we saw the potential in CNG (Compressed Natural Gas) as a cleaner,
              more efficient fuel alternative. With that vision in mind, we
              developed a reliable process to convert petrol engines to CNG,
              making it easier for vehicle owners to lower their carbon
              footprint and fuel costs. From those early days, we’ve grown into
              a trusted leader in CNG vehicle conversions, working with drivers,
              fleet owners, and businesses to create cleaner and more
              sustainable transportation solutions. Our dedication to innovation
              and quality service has allowed us to help countless clients
              transition to greener fuel, one vehicle at a time. Today, our
              commitment remains the same: to make eco-friendly transportation
              accessible to all while contributing to a healthier planet for
              future generations.
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(${bene1})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" w-full md:w-[48%] h-[520px] rounded-[20px] bg-[#00000031]"
          ></div>
        </div>
      </div>
      {/*The Problem */}
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          The Problem
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          The Problem We’re Solving
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          The rising cost of fuel and economic challenges are placing an
          unsustainable burden on vehicle owners and operators in Nigeria.
        </p>

        {/* benefit flex box 1*/}
        <div className=" w-full mt-16 flex flex-col md:flex-row items-center justify-between">
          <div
            style={{
              backgroundImage: `url(${bene2})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" w-full md:w-[48%] h-[520px] rounded-[20px] bg-[#00000031]"
          ></div>
          <div className=" w-full md:w-[48%] mt-8 md:mt-0">
            <p className=" font-Outfit text-2xl md:text-3xl md:leading-[41px] font-bold text-black">
              The problem
            </p>
            <p className=" text-base md:text-lg font-normal font-Outfit text-black mt-6">
              The removal of fuel subsidies and the sharp devaluation of the
              Nigerian Naira have created a significant financial strain on
              vehicle owners and transportation operators across the country.
              The cost of petrol has skyrocketed, making it nearly impossible
              for most Nigerians, particularly the working class and poor, to
              sustain regular vehicle use. The cost of transportation and food
              has surged by over 60%, worsening the economic hardship for
              millions.
              <br />
              In response to this crisis, the Nigerian government is encouraging
              the adoption of Compressed Natural Gas (CNG) as a cleaner, more
              affordable fuel alternative for vehicles and industrial power.
              However, the high upfront cost of converting vehicles to
              CNG—averaging around N1,000,000 per conversion—remains out of
              reach for many consumers, fleet owners, and transport operators.
            </p>
          </div>
        </div>
      </div>
      {/* Why we exist */}
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Why we exist
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Why Day Runner Exists
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          Providing affordable and scalable CNG conversion solutions to ease the
          burden of rising fuel costs.
        </p>

        {/* benefit flex box 1*/}
        <div className=" w-full mt-16 flex flex-col-reverse md:flex-row items-center justify-between">
          <div className=" w-full md:w-[48%] mt-8 md:mt-0">
            <p className=" font-Outfit text-2xl md:text-3xl md:leading-[41px] font-bold text-black">
              The Solution we provide.
            </p>
            <p className=" text-base md:text-lg font-normal font-Outfit text-black mt-6">
              Day Runner exists to solve the problem of the high upfront cost
              associated with CNG conversion. We offer an affordable,
              accessible, and scalable solution for consumers, fleet operators,
              and businesses. By partnering with one of the largest global
              manufacturers of CNG conversion kits and collaborating with a
              microlender, we are able to provide rapid CNG conversion services.
              Our unique financing option allows fleet and enterprise customers
              to spread out the conversion cost over time, making CNG conversion
              more attainable for all.
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(${bene3})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" w-full md:w-[48%] h-[520px] rounded-[20px] bg-[#00000031]"
          ></div>
        </div>
      </div>

      {/* Our Mission & Vision */}
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Our Mission & Vision
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Our Commitment to a Greener Future
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          Driven by our dedication to sustainability, we aim to transform the
          automotive landscape by converting petrol vehicles to CNG. Our mission
          is to deliver innovative, eco-friendly solutions that reduce emissions
          and fuel costs, while our vision is to lead the way in creating a
          cleaner, more sustainable transportation industry for generations to
          come.
        </p>

        <div className=" mt-16 w-full grid grid-cols-1 md:grid-cols-2 gap-8">
          {states.map((item, index) => (
            <div
              key={index}
              className="border border-[#DAE0E6] rounded-[20px] p-12"
            >
              <img src={item.img} alt="" />
              <p className=" font-Outfit text-black font-bold text-3xl mt-6">
                {item.name}
              </p>
              <p className=" mt-3 font-Outfit text-lg font-normal text-black">
                {item.desc}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Our Team */}
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Our Team
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Meet the dedicated professionals behind Day Runner
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          Our team is composed of skilled experts and passionate individuals who
          are committed to driving innovation in CNG vehicle conversions. With
          diverse backgrounds and extensive experience, each member brings
          unique insights and expertise to ensure we deliver the highest quality
          solutions and exceptional service.
        </p>

        <div className=" mt-16 w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          {team.map((item, index) => (
            <div key={index} className=" w-full">
              <div className=" w-full h-[300px] border-[#F0EEED] rounded-[20px] bg-[#00000031]"></div>
              <p className=" font-Outfit text-black font-medium text-xl mt-4">
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Faq />
      <Footer />
    </>
  );
};

export default About;
