import Navbar from "../components/navbar";
import heroImg from "./assets/heroImg.svg";
import works1 from "./assets/works1.svg";
import works2 from "./assets/works2.svg";
import works3 from "./assets/works3.svg";
import bene1 from "./assets/bene1.svg";
import bene2 from "./assets/bene2.svg";
import bene3 from "./assets/bene3.svg";
import gasvalve from "./assets/gasvalve.svg";
import tightbox from "./assets/tightbox.svg";
import multival from "./assets/multivalve.svg";
import sgi from "./assets/regulator.svg";
import Blog from "../components/blog";
import Faq from "../components/faq";
import Footer from "../components/footer";

const Landing = () => {
  const kits = [
    { name: "CNG GAS VALVE", img: gasvalve },
    { name: "AIR TIGHT BOX", img: tightbox },
    { name: "MULTIVALUE", img: multival },
    { name: "SGI REGULATOR", img: sgi },
  ];
  return (
    <>
      <Navbar />
      {/* hero */}
      <div
        style={{
          backgroundImage: `url(${heroImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className=" w-full h-[800px] md:h-[600px] relative"
      >
        <div className=" absolute left-0 top-0 w-full h-full bg-[#0000003c] flex flex-col items-center justify-center px-4 md:px-[10%]">
          <p className=" text-white font-Outfit font-bold text-5xl md:text-[55px] md:leading-[67px] text-center">
            Revolutionizing the Future of Transportation with CNG
          </p>
          <p className=" font-Outfit text-white mt-4 md:mt-3 text-base md:text-lg text-center font-normal">
            Switch to Compressed Natural Gas (CNG) and reduce your fuel costs by
            over 70%. CNG is cheaper, cleaner, and safer than petrol or diesel,
            giving you the power to save while protecting the environment.
          </p>
          <div className=" flex items-center space-x-3">
            <button className=" mt-8 py-3 px-6 bg-[#096F00] rounded-[40px] text-white font-Outfit font-semibold text-sm md:text-[15px]">
              Schedule a calendar
            </button>
            <button className=" mt-8 py-3 px-6 bg-[#FFFFFF] rounded-[40px] text-[#096F00] font-Outfit font-semibold text-sm md:text-[15px]">
              Learn more
            </button>
          </div>
        </div>
      </div>

      {/* how it works */}
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          How it works
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Step-by-step guide to converting your vehicle to CNG.
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          At Day Runner, we simplify converting your petrol vehicle to CNG. Just
          follow these easy steps to start saving money and cutting emissions
          today.
        </p>
        <div className=" w-full mt-16 grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* grid 1 */}
          <div className=" w-full">
            <div
              style={{
                backgroundImage: `url(${works1})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className=" w-full h-[240px] rounded-[20px] bg-[#00000031]"
            ></div>
            <p className=" font-Outfit mt-8 font-bold text-2xl text-black">
              Step 1: Vehicle Assessment
            </p>
            <p className=" mt-3 font-Outfit font-normal text-black text-base">
              Submit your vehicle details online for an initial eligibility
              check, then bring it in for a comprehensive on-site assessment to
              potentially join our conversion waiting list.
            </p>
          </div>
          {/* grid 2 */}
          <div className=" w-full">
            <div
              style={{
                backgroundImage: `url(${works2})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className=" w-full h-[240px] rounded-[20px] bg-[#00000031]"
            ></div>
            <p className=" font-Outfit mt-8 font-bold text-2xl text-black">
              Step 2: Conversion Process
            </p>
            <p className=" mt-3 font-Outfit font-normal text-black text-base">
              We'll convert your vehicle to CNG in 1-2 days when your turn
              comes, keeping you informed and offering convenient pickup and
              drop-off services.
            </p>
          </div>
          {/* grid 3 */}
          <div className=" w-full">
            <div
              style={{
                backgroundImage: `url(${works3})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className=" w-full h-[240px] rounded-[20px] bg-[#00000031]"
            ></div>
            <p className=" font-Outfit mt-8 font-bold text-2xl text-black">
              Step 3: Quality Assurance
            </p>
            <p className=" mt-3 font-Outfit font-normal text-black text-base">
              After conversion, we conduct thorough safety and efficiency
              checks, guide you on new features and maintenance, and ensure
              dual-fuel capability before returning your vehicle.
            </p>
          </div>
        </div>
      </div>

      {/* benefits */}
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Benefits of converting your vehicle
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Enjoy the Benefits of CNG Conversion for Your Vehicle
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          Experience greater savings, enhanced safety, and a cleaner drive by
          switching to Compressed Natural Gas (CNG) today.
        </p>
        {/* benefit flex box 1*/}
        <div className=" w-full mt-16 flex flex-col-reverse md:flex-row items-center justify-between">
          <div className=" w-full md:w-[48%] mt-8 md:mt-0">
            <p className=" font-Outfit text-2xl md:text-3xl md:leading-[41px] font-bold text-black">
              Significant Long-Term Savings on Fuel Costs
            </p>
            <p className=" text-base md:text-lg font-normal font-Outfit text-black mt-6">
              Day Runner helps you cut your fuel expenses by over 70%, offering
              a highly cost-effective alternative to petrol and diesel. With CNG
              priced at just N230/L, you’ll see substantial long-term savings.
            </p>
            <button className="px-5 py-3 mt-8 font-Outfit text-base rounded-[40px] font-medium shadow hover:shadow-[#007272] transition-all bg-[#096F00] text-white">
              Get Started Now
            </button>
          </div>
          <div
            style={{
              backgroundImage: `url(${bene1})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" w-full md:w-[48%] h-[480px] rounded-[20px] bg-[#00000031]"
          ></div>
        </div>
        {/* benefit flex box 2*/}
        <div className=" w-full mt-32 flex flex-col md:flex-row items-center justify-between">
          <div
            style={{
              backgroundImage: `url(${bene2})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" w-full md:w-[48%] h-[480px] rounded-[20px] bg-[#00000031]"
          ></div>
          <div className=" w-full md:w-[48%] mt-8 md:mt-0">
            <p className=" font-Outfit text-2xl md:text-3xl md:leading-[41px] font-bold text-black">
              Environmentally Friendly and Safer Alternative
            </p>
            <p className=" text-base md:text-lg font-normal font-Outfit text-black mt-6">
              Switching to CNG reduces your carbon footprint while providing a
              safer fuel option. CNG emits fewer pollutants and has a higher
              ignition temperature, making it both eco-friendly and safer for
              daily use.
            </p>
            <button className="px-5 py-3 mt-8 font-Outfit text-base rounded-[40px] font-medium shadow hover:shadow-[#007272] transition-all bg-[#096F00] text-white">
              Get Started Now
            </button>
          </div>
        </div>

        {/* benefit flex box 3*/}
        <div className=" w-full mt-32 flex flex-col-reverse md:flex-row items-center justify-between">
          <div className=" w-full md:w-[48%] mt-8 md:mt-0">
            <p className=" font-Outfit text-2xl md:text-3xl md:leading-[41px] font-bold text-black">
              Seamless Conversion with Full Support
            </p>
            <p className=" text-base md:text-lg font-normal font-Outfit text-black mt-6">
              Day Runner ensures a smooth and hassle-free transition to CNG.
              From professional installation to ongoing maintenance, our expert
              team is with you every step of the way to guarantee reliable
              performance and peace of mind.
            </p>
            <button className="px-5 py-3 mt-8 font-Outfit text-base rounded-[40px] font-medium shadow hover:shadow-[#007272] transition-all bg-[#096F00] text-white">
              Get Started Now
            </button>
          </div>
          <div
            style={{
              backgroundImage: `url(${bene3})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className=" w-full md:w-[48%] h-[480px] rounded-[20px] bg-[#00000031]"
          ></div>
        </div>
      </div>

      {/* cng kits snip */}
      <div className="w-full py-8 md:py-16 px-4 md:px-14 flex flex-col items-center justify-center">
        <button className=" px-6 py-3 border-[3px] border-[#E4ECEA] rounded-[40px] text-center text-base text-[#063126] font-Outfit">
          Our CNG Kits
        </button>
        <p className=" text-center mt-6 font-Outfit text-[#000] text-3xl font-bold md:px-[12%] md:leading-[58px] md:text-[48px]">
          Premium CNG Kits for Safe, Efficient, and Affordable Conversion
        </p>
        <p className=" mt-3 text-lg font-Outfit font-normal text-black leading-[27px] md:px-[10%] text-center">
          Experience the Best in CNG Technology with Kits Tailored for Maximum
          Performance and Reliability
        </p>
        <div className=" mt-16 w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          {kits.map((item, index) => (
            <div key={index} className=" w-full">
              <div className=" w-full p-12 border border-[#F0EEED] bg-white rounded-[12px] flex items-center justify-center">
                <img src={item.img} className="" alt="" />
              </div>
              <p className=" mt-4 font-Outfit font-medium text-black text-base md:text-xl">
                {item.name}
              </p>
            </div>
          ))}
        </div>
        <button className=" border border-[#096F00] rounded-[62px] py-4 px-14 mt-16 text-center font-Outfit text-[#096F00] font-medium text-base">
          View All
        </button>
      </div>

      {/* blogs */}
      <Blog />

      {/* faq */}
      <Faq />

      {/* footer */}
      <Footer />
    </>
  );
};

export default Landing;
