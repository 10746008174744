import { useState } from "react";
import stepper from "./assets/Stepper4.svg";

const Location = ({ handleBack, handleNext }) => {
  const [option, setOption] = useState("");
  const method = [
    {
      name: "In-Person",
      desc: "Drop off your vehicle at the service center",
    },
    {
      name: "Delivery Service",
      desc: "We’ll pick up and drop off your vehicle at your location",
    },
  ];

  return (
    <>
      <img src={stepper} className=" w-full mt-4 hidden md:block" alt="" />
      <p className=" font-Outfit font-bold text-[#000] text-2xl mt-3">
        Location Preferences
      </p>
      <label
        htmlFor="make"
        className="w-full mt-6 flex flex-col text-[#272D37] font-Outfit font-medium text-sm"
      >
        Choose Your Nearest Service Center
        <span className="w-full mt-2 border border-[#DAE0E6] rounded-[6px] px-4 py-3">
          <select
            name="service"
            id="service"
            className="w-full text-[#919BA7] font-normal font-Outfit text-sm"
          >
            <option value="">Choose Your Nearest Service Center</option>
          </select>
        </span>
      </label>

      <p className=" mt-4 text-[#272D37] font-Outfit font-medium text-sm">
        Pick-Up/Drop-Off Method
      </p>
      <div className="mt-3">
        {method.map((item) => (
          <div
            key={item.id}
            className={`p-4 mb-3 rounded-lg flex flex-row justify-between items-start cursor-pointer ${
              option === item.name
                ? "border-[#096F00] border-2"
                : "border-[#EAEBF0] border"
            }`}
            // onClick={() => handleOptionSelection(item)}
          >
            <div className=" flex items-start space-x-4">
              <div className="round">
                <input
                  type="checkbox"
                  id={item.name}
                  checked={option === item.name}
                  readOnly
                />
                <label htmlFor={item.name}></label>
              </div>
              <span className=" -mt-1">
                <p
                  className={`font-Outfit text-base font-medium ${
                    option === item.name ? "text-[#096F00]" : "text-[#272D37]"
                  }`}
                >
                  {item.name}
                </p>
                <p
                  className={`font-Outfit font-normal text-sm ${
                    option === item.name ? "text-[#096F00]" : "text-[#5F6D7E]"
                  }`}
                >
                  {item.desc}
                </p>
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-4 pt-6 pb-4">
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#272D37] font-semibold border border-[#DAE0E6] text-base`}
          onClick={handleBack} // Call handleBack when the Back button is clicked
        >
          Back
        </button>
        <button
          className={`w-full py-3 font-Outfit rounded-[40px] text-[#fff] bg-[#096F00] font-semibold flex justify-center items-center text-base`}
          onClick={handleNext} // Call handleNext when the Next button is clicked
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Location;
